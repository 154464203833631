<template>
  <b-overlay
    :show="serviceStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <component :is="serviceDataInfo === undefined ? 'div' : 'b-card'">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="serviceDataInfo === undefined"
      >
        <h4 class="alert-heading">
          Error fetching service data
        </h4>
        <div class="alert-body">
          No service found with this service id. Check
          <b-link
            class="alert-link"
            :to="{ name: SERVICE_LIST}"
          >
            Service List
          </b-link>
          for other services
        </div>
      </b-alert>
      <div v-if="serviceDataInfo">
        <!-- Header: Service Info -->
        <div class="d-flex">
          <feather-icon
            icon="UserIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Service Edit
          </h4>
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form: Service Info Form -->
          <b-form
            class="mt-1"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <!-- Field: Name -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="serviceDataInfo.name"
                      :state="errors[0] ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Price -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  name="Price"
                  rules="required|regex:^(([1-9])*([0])*)(\.(\d{1})(\d{1})?)?$|greaterThanZero"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Price ($)"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      v-model="serviceDataInfo.price"
                      :state="errors[0] ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Duration -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  name="Duration"
                  rules="required|integer|regex:^[1-9]\d*$"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Duration (m)"
                    label-for="duration"
                  >
                    <b-form-input
                      id="duration"
                      v-model="serviceDataInfo.duration"
                      :state="errors[0] ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Hourly -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Hourly"
                  label-for="hourly"
                >
                  <v-select
                    id="hourly"
                    v-model="serviceDataInfo.hourly"
                    :options="[{
                      value: 0,
                      label: 'No'
                    }, {
                      value: 1,
                      label: 'Yes'
                    }]"
                    :reduce="label => label.value"
                    :clearable="false"
                    input-id="hourly"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Category"
                  label-for="category"
                >
                  <treeselect
                    id="category"
                    v-model="serviceDataInfo.category"
                    :options="options"
                    :auto-load-root-options="true"
                    :disable-branch-nodes="false"
                    :searchable="false"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Public -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Public"
                  label-for="public"
                >
                  <v-select
                    id="public"
                    v-model="serviceDataInfo.public"
                    :options="[{
                      value: 0,
                      label: 'No'
                    }, {
                      value: 1,
                      label: 'Yes'
                    }]"
                    :reduce="label => label.value"
                    :clearable="false"
                    input-id="public"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >
                  Save Changes
                </b-button>
                <b-button
                  variant="outline-secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { SERVICE_LIST } from '@/router/routes/routes-names'
import { useToast } from 'vue-toastification/composition'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState } from 'vuex'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import stylistServiceStoreModule from './StylistServiceStoreModule'

extend('greaterThanZero', {
  validate(value) {
    return value > 0
  },
  computesRequired: true,
  message: 'Price must be greater than 0',
})

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    Treeselect,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      options: [],
    }
  },
  computed: {
    ...mapState(
      'StylistServiceStoreModule', {
        categories: state => state.categories,
        serviceStore: state => state.service,
      },
    ),
  },
  watch: {
    categories: {
      deep: true,
      immediate: true,
      handler(newDetails) {
        if (newDetails) {
          this.options = newDetails.response
        }
      },
    },
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    ...mapActions('StylistServiceStoreModule', [
      'fetchCategories',
    ]),
  },
  setup() {
    const serviceDataInfo = ref({
      id: null,
      name: null,
      price: null,
      hourly: null,
      duration: null,
      public: null,
      category: null,
    })

    const categoryDataInfo = ref({})

    const SERVICE_APP_STORE_MODULE_NAME = 'stylist-service'

    // Register module
    if (!store.hasModule(SERVICE_APP_STORE_MODULE_NAME)) store.registerModule(SERVICE_APP_STORE_MODULE_NAME, stylistServiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    store.dispatch('stylist-service/fetchService', { id: router.currentRoute.params.id })
      .then(response => {
        serviceDataInfo.value = {
          id: response.data.id,
          name: response.data.name,
          price: response.data.price,
          hourly: response.data.hourly === true ? 1 : 0,
          duration: response.data.duration,
          public: response.data.public === true ? 1 : 0,
          category: response.data.category.id,
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          serviceDataInfo.value = undefined
        }
      })

    const userData = ref(JSON.parse(JSON.stringify(serviceDataInfo)))
    const resetCustomerData = () => {
      userData.value = JSON.parse(JSON.stringify(serviceDataInfo))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetCustomerData)

    const onSubmit = () => {
      store.dispatch('stylist-service/updateService', {
        id: serviceDataInfo.value.id,
        name: serviceDataInfo.value.name,
        price: serviceDataInfo.value.price,
        hourly: Boolean(serviceDataInfo.value.hourly),
        duration: Number(serviceDataInfo.value.duration),
        public: Boolean(serviceDataInfo.value.public),
        category: serviceDataInfo.value.category,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Service has been updated',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          router.push({
            name: SERVICE_LIST,
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Service has not been updated',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {
      serviceDataInfo,
      categoryDataInfo,
      onSubmit,
      refFormObserver,
      resetForm,
      SERVICE_LIST,
    }
  },
}
</script>

<style>

</style>
